import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnansweredQuestionModel } from './unanswered-question.model';

@Component({
  selector: 'app-unanswered-co2-popup',
  templateUrl: './unanswered-popup-simple.component.html',
  styleUrls: ['./unanswered-popup-simple.component.scss'],
})
export class UnansweredPopupSimpleComponent {
  questions: UnansweredQuestionModel[] | undefined;

  mentionInvalidAnswers: boolean;

  constructor(
    public dialogRef: MatDialogRef<UnansweredPopupSimpleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { questions: UnansweredQuestionModel[]; mentionInvalidAnswers: boolean },
    private ngZone: NgZone,
  ) {
    const questionsToSort = [...data.questions];

    this.mentionInvalidAnswers = data.mentionInvalidAnswers;
    this.questions = questionsToSort.sort((a, b) => {
      if (a.category === b.category) {
        if (a.id.includes('.')) {
          if (b.id.includes('.')) {
            return this.compareNumbersWithDots(a.id, b.id);
          }

          return this.compareAsNumbers(a.id.substring(0, a.id.indexOf('.')), b.id);
        }

        if (b.id.includes('.')) {
          return this.compareAsNumbers(a.id, b.id.substring(0, b.id.indexOf('.')));
        }

        return this.compareAsNumbers(a.id, b.id);
      }

      return a.categoryOrder < b.categoryOrder ? -1 : 1;
    });
  }

  onNoClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  private compareNumbersWithDots(numberA: string, numberB: string): number {
    const startA = numberA.substring(0, numberA.indexOf('.'));
    const startB = numberB.substring(0, numberB.indexOf('.'));

    if (startA !== startB) {
      return this.compareAsNumbers(startA, startB);
    }

    return this.compareSecondHalves(numberA, numberB);
  }

  private compareSecondHalves(numberA: string, numberB: string): number {
    const endA = numberA.substring(numberA.lastIndexOf('.') - 1, numberA.length);
    const endB = numberB.substring(numberB.lastIndexOf('.') - 1, numberB.length);

    return this.compareAsNumbers(endA, endB);
  }

  private compareAsNumbers(numberA: string, numberB: string): number {
    return parseInt(numberA, 10) <= parseInt(numberB, 10) ? -1 : 1;
  }
}
